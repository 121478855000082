import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'


import servicosStyles from './servicos.module.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ServicosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      servicoImagem: file(relativePath: { eq: "tool.jpg" }) {
        childImageSharp {
          fluid (quality: 50, maxWidth: 1920, maxHeight: 770, cropFocus: SOUTH){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Serviços"/>
      <div className={servicosStyles.mainContent}>
        <div className={servicosStyles.servicosTexto}>
          <h1>
            Serviços
          </h1>
          <p>Confira os serviços que disponibilizamos.</p>
        </div>
      </div>
      <Img fluid={data.servicoImagem.childImageSharp.fluid} />
      

      <div className={servicosStyles.mainContent}>
        <h2 className={servicosStyles.tituloTopico}>Instalação</h2>
        <p>Realizamos em nossa loja a instalação dos alarmes da Cerruns.<br />
        <Link to="/contato">Ligue</Link> para agendar uma instalação.</p>

        <h2 className={servicosStyles.tituloTopico}>Manutenção</h2>
        <p>Nossa equipe técnica está preparada para diagnosticar e consertar seu produto Cerruns.
        Basta trazê-lo aqui ou enviá-lo para nós pelos correios.</p>
      </div>
    </Layout>
  )
}

export default ServicosPage